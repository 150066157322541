/*eslint-disable*/
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";
import { AuthContext } from "../../context/AuthContext";
import { auth } from "../../firebase/firebaseSetup";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  const user = useContext(AuthContext);

  useEffect(() => {
    console.log('HeaderLinks ', user)
  }, [user])


  const signOut = async () => {
    try {
      await auth.signOut().then(() => {
        console.log("signout succesful")
      })
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/home" className={classes.dropdownLink}>
          Home
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          Daily Feeds
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          Presentation
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          Feedback
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          About Us
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        {user ?
          <CustomDropdown
            noLiPadding
            buttonText={
              <img
                src={user.photoURL}
                className={classes.img}
                alt="profile"
              />}
            buttonProps={{
              className: classes.navLink + " " + classes.imageDropdownButton,
              color: "transparent",
            }}
            dropdownList={[
              <a
                onClick={signOut}
                className={classes.dropdownLink}
              >
                Sign Out
              </a>,
            ]}
          />
          : ""}
      </ListItem>
    </List>
  );
}
