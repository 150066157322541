
import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBpm9ITl3cJvN7Q7WhlWbqXgJTTjdFh9Vg",
    authDomain: "improveyourenglish-fc8c7.firebaseapp.com",
    databaseURL: "https://improveyourenglish-fc8c7.firebaseio.com",
    projectId: "improveyourenglish-fc8c7",
    storageBucket: "improveyourenglish-fc8c7.appspot.com",
    messagingSenderId: "267990315332"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const firestore = firebase.firestore();