import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./provider/AuthProvider";
import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/components/Components.js";
import LandingPage from "views/pages/LandingPage/LandingPage.js";
import ProfilePage from "views/pages/ProfilePage/ProfilePage.js";
import LoginPage from "views/pages/LoginPage/LoginPage.js";
import HomePage from "views/pages/HomePage/HomePage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Router history={hist}>
        <Switch>
          <Route path="/landing" component={LandingPage} />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/home" component={HomePage} />
          <Route path="/" component={Components} />
        </Switch>
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
