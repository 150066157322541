import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { container } from "assets/jss/material-kit-react.js";


const useStyles = makeStyles({
    section: {
        minHeight: "50vh",
        maxHeight: "1600px",
        overflow: "hidden",
        padding: "30px 0",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        margin: "0",
        border: "0",
        display: "flex",
        alignItems: "center",
    },
    container,
    subtitle: {
        fontSize: "1.1rem",
        margin: "10px 0 0",
        color: "black"
    },
    subtitle_right: {
        fontSize: "1.1rem",
        margin: "10px 0 0",
        color: "black",
        textAlign: "right"
    },
    subtitle_right_bold: {
        fontSize: "1.1rem",
        margin: "10px 0 0",
        color: "black",
        textAlign: "right"
    },
    brand: {
        color: "#FFFFFF",
        textAlign: "left",
    },
});
export default function HomeComponent() {
    const classes = useStyles();
    return (
        <div>
            <Grid container justify="center">
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <div className={classes.brand}>
                        <h3 className={classes.subtitle}>
                            Spoken English & Grammar – எளிதாக கற்றுக்கொள்ள, பல்வேறு நபர்களின் ஆலோசனைகளைக் கேட்டு, பல வருட கடின உழைப்பிற்கு பிறகு இதுவரை நீங்கள் பார்த்திராத வகையில் வெளிவந்துள்ள புத்தகம். படித்து பயன்பெறவும் பரிசளிக்கவும் ஏற்ற நூல்.
                            ஒவ்வொரு பள்ளி மற்றும் கல்லூரி மாணவர் வீட்டிலும் இருக்க வேண்டிய புத்தகம்.
                            மாணவர்களுக்கு அதிகம் பயன்படவேண்டும் என்ற நோக்கத்தோடு, இப்புத்தகத்தில் 528 பக்கங்களில் 40 தலைப்புகளில் கொடுக்கப்பட்டுள்ள பாடங்களில் மிக முக்கியமானதை தொகுத்து, 350-க்கும் மேற்பட்ட slide-களில் Power Point Presentation-இல் தலைப்பு வாரியாக கொடுக்கப்பட்டுள்ளன.
                            ஆசிரியர்கள் இதைப் பயன்படுத்தி மாணவர்களுக்கு கற்றுக் கொடுத்தால் மனதில் நன்றாக பதியும் என கருதுகிறேன். மேலும், ஏதாவது சந்தேகம் ஏற்பட்டால், கைபேசி மூலம் தொடர்பு கொள்ளலாம் எனவும் தெரிவித்துக்கொள்கிறேன்.
                        </h3>
                        <div className={classes.subtitle_right}>
                            என்றும் கல்விப் பணியில்,
                        </div>
                        <div className={classes.subtitle_right_bold}>
                            S.T.Somasundaram
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>

    )
}


