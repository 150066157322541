/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FacebookIcon from '@material-ui/icons/Facebook';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import TelegramIcon from '@material-ui/icons/Telegram';

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <IconButton aria-label="facebook" color="inherit" href="https://fb.com/stsimproveyourenglish" target="_blank">
                <FacebookIcon />
              </IconButton>
              <IconButton aria-label="mail" color="inherit" href='mailto:stsenglish@gmail.com' target='_blank'>
                <MailOutlineIcon />
              </IconButton>
              <IconButton aria-label="phone" color="inherit" href='tel:+919444661676' target='_blank'>
                <PhoneIcon />
              </IconButton>
              <IconButton aria-label="telegram" color="inherit" href='https://t.me/stsimproveyourenglish' target='_blank'>
                <TelegramIcon />
              </IconButton>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} STS Improve Your English
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
