import { Card, CardContent, CardHeader, Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { container } from "assets/jss/material-kit-react.js";
import { firestore } from "firebase/firebaseSetup";


const useStyles = makeStyles({
    section: {
        minHeight: "50vh",
        maxHeight: "1600px",
        overflow: "hidden",
        padding: "30px 0",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        margin: "0",
        border: "0",
        display: "flex",
        alignItems: "center",
    },
    container,
});
export default function TodaysFeedComponent() {

    const [todayFeedUrl, setTodayFeedUrl] = useState();
    const classes = useStyles();
    const todayFeedTitle = "Today's Feed";
    useEffect(() => {
        getTodayFeed();
    }, [])

    async function getTodayFeed() {
        const snapshot = await firestore
            .collection("image_data")
            .orderBy("timestamp", "desc")
            .limit(1)
            .get()

        if (snapshot.empty) {
            console.log("image_data is empty")
            return;
        }

        const data = snapshot.docs[0].data();

        // console.log('image_data ', data);

        setTodayFeedUrl(data.url);
    }
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                        <Paper>
                            {console.log('feed url ', todayFeedUrl)}
                            {todayFeedUrl ?
                                (
                                    <Card>
                                        <CardHeader title={todayFeedTitle} style={{ textAlign: 'center' }} />
                                        <CardContent>
                                            <img src={todayFeedUrl}
                                                alt={todayFeedTitle}
                                                style={{ width: '100%' }}
                                            />
                                        </CardContent>
                                    </Card>
                                )
                                : (<div>loading...</div>)
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div >
    )
}


