import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";


import styles from "assets/jss/material-kit-react/views/components.js";
import TodaysFeedComponent from "../../components/TodaysFeedComponent/TodaysFeedComponent.tsx";
import HomeComponent from "../../components/HomeComponent/HomeComponent.tsx";

const useStyles = makeStyles(styles);

export default function HomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        rightLinks={<HeaderLinks />}
        fixed
        color="primary"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax>
        <HomeComponent />
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <TodaysFeedComponent />
      </div>
      <Footer />
    </div>

  );
}
