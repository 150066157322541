import React, { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import firebase from "firebase/app";
import { auth } from "firebase/firebaseSetup";

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export const AuthProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<firebase.User | null>(null);

    useEffect(() => {
        auth.onAuthStateChanged((firebaseUser) => {
            if (firebaseUser?.uid === 'QEMiede5Fsagg1ayHRvPSY1khjv2' || firebaseUser?.uid === '1irr8yyrXMRcyYri9D6fUqTo3w02'
                || firebaseUser?.uid === 'H2mucUXAryfH1GMY1c4zyqSnGmx2') {
                setUser(firebaseUser);
            }else{
                setUser(null)
            }
            console.log('AuthProvider', firebaseUser);
        });
    }, []);

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
